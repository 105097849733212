@keyframes myAnimation{
  0%{
    opacity: 1;
    transform: rotateX(90deg);
  }
  50%{
    opacity: 0.5;
    transform: rotateX(0deg);
  }
  100%{
    display: none;
    opacity: 0;
    transform: rotateX(90deg);
  }
}

.annnn{
  animation-name: myAnimation;
  animation-duration: 3000ms;
  animation-fill-mode: forwards;
}