@font-face{
font-family: "AirbnbCereal Bold";
src: url("../fonts/AirbnbCereal_W_Bd.otf");    
}

@font-face{
    font-family: "AirbnbCereal Light";
    src: url("../fonts/AirbnbCereal_W_Lt.otf");    
    }

@font-face{
    font-family: "AirbnbCereal Medium";
    src: url("../fonts/AirbnbCereal_W_Md.otf");    
     }

 @font-face{
        font-family: "AirbnbCereal Book";
        src: url("../fonts/AirbnbCereal_W_Bk.otf");    
}

    